import React, { Component } from 'react';
//import { Row, Col, Navbar, Collapse, Container, NavbarBrand, Nav, UncontrolledDropdown, DropdownToggle, NavItem, NavLink, NavbarToggler} from 'reactstrap';

import APIClient from '../../../APIClient';

import './Video.scss';

export default class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pause: false
    };
    this.bindEvents();
    this.isLoading = true;
    this.video = React.createRef();
  }

  bindEvents() {
    this.onPlay = this.onPlay.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onEnded = this.onEnded.bind(this);
    this.onLoadedData = this.onLoadedData.bind(this);
    this.onSeeking = this.onSeeking.bind(this);
    this.onSeeked = this.onSeeked.bind(this);
  }

  componentDidMount() {
    /*this.videoEl = this.el.getElementsByTagName('video')[0];
    this.videoEl.load();
    this.videoEl.play();*/
  }

  shouldComponentUpdate(nextProps, nextState) {
    let update = this.isLoading;
    update |= this.props.video !== nextProps.video;
    update |= this.props.isPaused !== nextProps.isPaused;

    this.isLoading = false;
    return update;
  }

  componentDidUpdate(_prevProps, _prevState) {
    if(!this.props.video)
      return;
    if(_prevProps.video !== this.props.video) {
     this.video.current.load(); // you can add logic to check if sources have been changed
       // you can add logic to check if sources have been changed
    }
    this.video.current && this.props.isPaused ? this.video.current.pause() : this.video.current.play();

  }

  render() {
    let video = this.props.video;
    if(!video) return null;
    return (
      <div className="embed-responsive embed-responsive-16by9">
        <video ref={this.video} controls disablepictureinpicture="true" controlsList="nofullscreen nodownload" preload="auto" heigth="auto" width="auto"
          onPlay={this.onPlay} onPause={this.onPause} onEnded={this.onEnded} onLoadedData={this.onLoadedData} onSeeking={this.onSeeking} onSeeked={this.onSeeked}>
          {this.renderVideoSources(video)}
          Your browser does not support the video tag.
        </video>
      </div>
    )
  }

  renderVideoSources(video) {
    let key = 0;
    return video.sources.map(source => {
      return (
        <source key={key++} src={APIClient.getVideoURL(video.trainingId, video.id) + "?mime=" + encodeURI(source.mime)} type={source.mime} />
      )
    });
  }

  onPlay(e) {
    if(this.props.onPlay) {
      this.props.onPlay(this, e);
    }
  }

  onPause(e) {
    if(this.props.onPause) {
      this.props.onPause(this, e);
    }
  }

  onEnded(e) {
    if(this.props.onEnded) {
      this.props.onEnded(this, e);
    }
  }

  onSeeking(e) {
    if(this.props.onSeeking) {
      this.props.onSeeking(this, e);
    }
  }

  onSeeked(e) {
    if(this.props.onSeeked) {
      this.props.onSeeked(this, e);
    }
  }

  onLoadedData(e) {
    if(this.props.onLoadedData) {
      this.props.onLoadedData(this, e);
    }

    this.props.isPaused ?this.video.current.pause() :this.video.current.play();
  }

}
