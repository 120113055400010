import React, { Component } from 'react';
import { Row, Col, Container, Button} from 'reactstrap';

import Loading from '../../../components/common/Loading';

import APIClient from '../../../APIClient';
import TrainingContent from './TrainingContent';

import IDB from '../../../Utilities/IDB';
import TrainingSurvey from './TrainingSurvey';

import './Training.scss';

export default class Training extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trainingId: props.match.params.trainingId,
      surveyResponses: null,
      trainingCompleted: false
    };
    this.bindEvents();
  }

  bindEvents() {
    this.onSurveySubmitted = this.onSurveySubmitted.bind(this);
    this.onTrainingCompleted = this.onTrainingCompleted.bind(this);
  }

  componentDidMount() {
    IDB.getTraining(this.state.trainingId).then(training => {
      this.setState({training: training});
      return APIClient.getTraining(this.state.trainingId);
    }).catch(error => {
      let response = APIClient.getTraining(this.state.trainingId);
      this.setState({training: response.training});
      return response;
    }).then(response => {
      return response.training;
    }).then(training => {
      IDB.saveTraining(training);
      return APIClient.getVideos(this.state.trainingId);
    }).then(response => {
      //this.setState({videos: response.videos, surveyResponses: {bcdefghi: "b2defghi"} });
      this.setState({videos: response.videos});
      return response.videos;
    });
  }

  /*componentWillUpdate(nextProps, nextState) {
    let filterCriteria = nextState.filters;
    let sortCriteria = nextState.sortCriteria;
    nextState.filteredTraining = ArrayUtil.filter(nextState.training, filterCriteria);
    nextState.filteredTraining = ArrayUtil.sort(nextState.filteredTraining, sortCriteria);
  }*/

  render() {
    return (
      <Container className="trainingContainer">
        {this.renderHeading()}
        {this.renderSurvey()}
        {this.renderTraining()}
        {this.renderTrainingCompleted()}
      </Container>
    )
  }

  renderHeading() {
    if(!this.state.training) {
      return ( <Loading /> );
    }

    return (
      <Row>
        <Col md="12">
          <h2 className="text-center"><i className="fa fa-fw fa-graduation-cap"></i>{this.state.training.title}</h2>
        </Col>
      </Row>
    );
  }

  renderSurvey() {
    if(!this.state.training || this.state.surveyResponses) {
      return null;
    }

    return (
      <TrainingSurvey survey={this.state.training.survey} onSurveySubmitted={this.onSurveySubmitted} />
    );
  }

  onSurveySubmitted(surveyResponses) {
    this.setState({surveyResponses: surveyResponses});
  }

  renderTraining() {
    if(!this.state.surveyResponses || this.state.trainingCompleted) {
      return null;
    }

    return (
      <TrainingContent training={this.state.training} videos={this.state.videos} surveyResponses={this.state.surveyResponses} onTrainingCompleted={this.onTrainingCompleted} />
    );
  }

  onTrainingCompleted() {
    this.setState({trainingCompleted: true});
  }


  renderTrainingCompleted() {
    if(!this.state.trainingCompleted) {
      return null;
    }

    return (

      <Row className="trainingFinalMessage">
        <Col md="12">
          <h3 className="text-center">Congratulations! You have completed this training.</h3>
          <p className="text-center">
            <Button  color="primary" href="/">Close</Button>
          </p>
        </Col>
      </Row>
    );
  }

}
