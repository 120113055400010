import UUID from './UUID';

export default class TrainingGraph {
  constructor(graph, surveyResponses) {
    this.nodeHistory = [];
    this.currentNode = null;
    this.graph = {
      nodes: {},
      links: {},
      startNodeId: {}
    };
    this.surveyResponses = {};
    if(graph) {
      this.setGraph(graph);
    }
    if(surveyResponses) {
      this.surveyResponses = surveyResponses;
    }
  }

  setGraph(graph) {
    this.graph = graph;
    this.currentNode = this.getNode(this.graph.startNodeId);
    this.nodeHistory.push(this.currentNode);
  }

  addNode(type, resourceId) {
    var newNode = {
      id: 'n' + UUID.suid(),
      type: NodeType[type].type,
      resourceId: resourceId,
      linkIds: [],
      pos: {x: 0, y: 0}
    };
    this.graph.nodes[newNode.id] = newNode;
    return newNode;
  }

  getNode(nodeId) {
    return this.graph.nodes[nodeId];
    /*var newNode = new TrainingGraphNode(this.graph.nodes[nodeId]);
    return newNode;*/
  }

  addLink(type, sourceId, targetId) {
    var newLink = {
      id: 'l' + UUID.suid(),
      sourceId: sourceId,
      targetId: targetId,
      type: LinkType[type].type,
      conditionalExpression: null
    };

    this.graph.links[newLink.id] = newLink;
    this.graph.nodes[sourceId].linkIds.push(newLink.id);
    return newLink;
  }

  getLink(linkId) {
    return this.graph.links[linkId];
    /*var newLink = new TrainingGraphLink(this.graph.links[linkId]);
    console.log(newLink);
    return newLink;*/
  }

  linkMatches(link, surveyResponses, assessmentResponse) {
    if(!link.conditionalExpression) {
      return true;
    }
    let matchFound = true;
    for(const key in link.conditionalExpression.sr) {
      if(surveyResponses[key] !== link.conditionalExpression.sr[key]) {
        matchFound = false;
      }
    }
    if(matchFound && link.conditionalExpression.ar) {
      matchFound = link.conditionalExpression.ar[assessmentResponse] === assessmentResponse;
    }
    return matchFound;
  }

  getCurrentNode() {
    return this.currentNode;
  }

  nextNode(response, saveHistory = true) {
    for(let i in this.currentNode.linkIds) {
      const link = this.getLink(this.currentNode.linkIds[i]);
      if(this.linkMatches(link, this.surveyResponses, response)) {
        this.currentNode = this.getNode(link.targetId);
        if(this.currentNode.type === NodeType.survey.type) {
          this.currentNode = this.nextNode(response, false);
        }
        /*switch(nextNode.type) {
          case NodeType.normal.type:
          case NodeType.question.type:
            this.setVideo(node.resourceId);
            break;
          case NodeType.survey.type:
            this.nextNode();
            break;
          default:
        }
*/
        if(saveHistory) this.nodeHistory.push(this.currentNode);
        return this.currentNode;
      }
    }
    this.currentNode = null;
    return this.currentNode;
  }

  getGraph() {
    return this.graph;
  }

  serialize() {
    return JSON.stringify(this.graph);
  }

  goToHistory(historyIndex) {
    this.nodeHistory.splice(historyIndex + 1);
    this.currentNode = this.nodeHistory[this.nodeHistory.length - 1];
    return this.currentNode;
  }
}



export const VideoType = {
  "content": {type: "content", title: "Content Video"},
  "question": {type: "question", title: "Assessment Question"},
  "reply": {type: "reply", title: "Assessment Question Followup"},
  "answer": {type: "answer", title: "Answer to Question"},
};

export const SurveyQuestionType = {
  "radio": {type: "radio", title: "Single Choice"},
  "checkbox": {type: "checkbox", title: "Multiple Choice"},
  "number": {type: "number", title: "Numeric"}
};


export const NodeType = {
  "normal": {type: "normal", title: "Normal node"},
  "survey": {type: "survey", title: "Survey Node"},
  "question": {type: "question", title: "Assessment Question"},
};


export const LinkType = {
  "normal": {type: "normal", title: "Normal link"},
  "fixed": {type: "fixed", title: "Fixed link"}
};
