import React, { Component } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText, FormGroup, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import Loading from '../../common/Loading';

import APIClient from '../../../APIClient';

//import IDB from '../../Utilities/IDB';

import './LearnerQuestion.scss';

export default class LearnerQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFAQ: null,
      question: "",
      faqs: null,
      speech: this.getSpeechRecognition(),
      isSpeaking: false
    };
    this.bindEvents();
  }

  bindEvents() {
    this.onSpeechAction = this.onSpeechAction.bind(this);
    this.onAsk = this.onAsk.bind(this);
    this.onQuestionChange = this.onQuestionChange.bind(this);
  }

  getSpeechRecognition() {
    let Speech = window.SpeechRecognition || window.webkitSpeechRecognition;
    if(!Speech)
      return null;
    let speechEngine = new Speech();
    speechEngine.onstart = this.onSpeechStart.bind(this);
    speechEngine.onresult = this.onSpeechResult.bind(this);
    speechEngine.onspeechend = this.onSpeechEnd.bind(this);
    speechEngine.onerror = this.onSpeechError.bind(this);
    return speechEngine;
  }

  onSpeechStart() {
    console.log("Start");
    this.setState({ isSpeaking: true });
  }

  onSpeechResult(event) {
    var current = event.resultIndex;
    // Get a transcript of what was said.
    var transcript = event.results[current][0].transcript;
    this.setState({
      question: transcript,
      isSpeaking: false
    });
  }

  onSpeechEnd() {
    this.setState({ isSpeaking: false });
  }

  onSpeechError(event) {
    this.setState({ isSpeaking: false });
  }

  componentDidMount() {
    APIClient.getVideoFAQs(this.props.training.id, this.props.video.id)
    .then(response => {
      this.setState({faqs: response.faqs});
    });
  }

  render() {
    return (
      <Modal isOpen={true}>
        <ModalHeader>Ask a question</ModalHeader>
        <ModalBody>
          <FormGroup tag="fieldset">
            <p><strong>Select one of the FAQs:</strong></p>
            {this.renderFAQs()}
            <br />
            <p><strong>Or ask your own question:</strong></p>

            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Input addon type="radio" name="faq" checked={!this.state.selectedFAQ} onChange={(e) => { this.onFAQOptionChange(e, null) } } />
                </InputGroupText>
              </InputGroupAddon>
              <Input placeholder="Type your question" value={this.state.question} onChange={this.onQuestionChange} />

              <InputGroupAddon addonType="append">
                <Button color="secondary" onClick={this.onSpeechAction}>
                  {this.state.isSpeaking ? <i className="fas fa-fw fa-circle-notch fa-spin"></i> :
                  <i className="fas fa-fw fa-microphone"></i>}
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={this.props.onCancel}>Cancel</Button>{' '}
          <Button color="primary" onClick={this.onAsk} disabled={!this.state.selectedFAQ && this.state.question === ''}>Ask</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderFAQs() {
    if(!this.state.faqs) {
      return ( <Loading /> );
    }
    else if(this.state.faqs.length === 0) {
      return ( <p ng-if="results.length == 0">There are no FAQs for this video</p> );
    }
    return this.state.faqs.map(faq => {
      return (
        <FormGroup check key={faq.id}>
          <Label check>
            <Input type="radio" name="faq" value={faq.id} checked={this.state.selectedFAQ === faq} onChange={(e) => { this.onFAQOptionChange(e, faq) } } />
            {faq.question}
          </Label>
        </FormGroup>
      );
    });

  }

  onFAQOptionChange(e, faq) {
    this.setState({
      selectedFAQ: faq
    });
  }

  onQuestionChange(e) {
    this.setState({
      selectedFAQ: null,
      question: e.target.value
    });
  }

  onSpeechAction() {
    this.state.isSpeaking ? this.state.speech.stop() : this.state.speech.start();
    this.setState({
      selectedFAQ: null,
      isSpeaking: !this.state.isSpeaking
    });
  }

  onAsk() {
    if(this.props.onAsk) {
      this.props.onAsk(this.state.selectedFAQ ? this.state.selectedFAQ.question : this.state.question, this.state.selectedFAQ);
    }
  }




}
