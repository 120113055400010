import React, { Component } from 'react';
import { Row, Col, Card, FormGroup, Form, Button, Input, CardBody, CardTitle, CardText, Label} from 'reactstrap';

import Loading from '../../../components/common/Loading';

import ArrayUtil from '../../../Utilities/ArrayUtil';
import './TrainingSurvey.scss';

export default class TrainingSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      survey: props.survey,
      surveyResponses: {}
    };

    this.bindEvents();
  }

  bindEvents() {
    this.onSubmit = this.onSubmit.bind(this);
  }

  render() {
    if(!this.props.survey) {
      return ( <Loading  /> )
    }

    return (
      <Row>
        <Col md={{offset: 2, size: 8}} sm="12">
          <br />
          <h3>Let's begin by customizing this training for you.</h3>
          <p>Please answer the following questions about yourself and your previous experiences.</p>

          <Form onSubmit={this.onSubmit} role="form">
            {this.renderSurveyQuestions()}
            <br />
            <div className="text-center">
              <Button color="primary" type="submit">Customize My Training</Button>
              {' '}
              <Button href="/">Cancel</Button>
            </div>
          </Form>
        </Col>
      </Row>
    );
  }

  renderSurveyQuestions() {
    let questions = Object.keys(this.state.survey).map(key => {
      return this.state.survey[key];
    });
    return ArrayUtil.sort(questions, "number").map(question => {
      switch(question.type) {
        case "radio":
        case "checkbox":
          return (
            <Card key={question.id}>
              <CardBody>
              <CardTitle>{question.number}.- {question.question}</CardTitle>
                <CardText tag="div" className={question.type}>
                  <FormGroup tag="fieldset" style={{marginLeft: "30px"}}>
                      {this.renderSurveyQuestionOptions(question)}
                  </FormGroup>
                </CardText>
              </CardBody>
            </Card>
          );
        case "number":
          return (
            <Card key={question.id}>
              <CardBody>
                <CardTitle>{question.number}.- {question.question}</CardTitle>
                <CardText tag="div" className={question.type}>
                  <Input type={question.type} name={question.id} onChange={(e) => { this.onChange(e, question.id) } } required={!this.state.surveyResponses[question.id]} />
                </CardText>
              </CardBody>
            </Card>
          );
        default:
        return null;
      }
    });


  }


  renderSurveyQuestionOptions(question) {
    return Object.keys(question.options).map(key => {
      let option = question.options[key];
      return (
        <FormGroup check key={option.id}>
          <Label check style={{display: "block"}}>
            <Input type={question.type} name={question.id} value={option.id} onChange={(e) => { this.onChange(e, question.id) } } required={!this.state.surveyResponses[question.id]} />
            {option.text}
          </Label>
        </FormGroup>
      );
    });

  }

  onChange(e, key) {
    let surveyResponses = this.state.surveyResponses;
    surveyResponses[key] = e.target.value;
    this.setState({
      surveyResponses: surveyResponses
    });
  //onChange={ (e) => this.setState({ text: e.target.value }) }
  }



  onSubmit(event) {
    event.preventDefault();
    console.log(this.state.surveyResponses);
    if(this.props.onSurveySubmitted) {
      this.props.onSurveySubmitted(this.state.surveyResponses);
    }
  }


}
