import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import { DropdownMenu, DropdownItem, Navbar, Collapse, Container, NavbarBrand, Nav, UncontrolledDropdown, DropdownToggle, NavItem, NavLink, NavbarToggler} from 'reactstrap';

import {UserContext} from '../../contexts/UserProvider';
import {PermissionProvider} from '../../contexts/PermissionProvider';

import {Role} from '../../Constants';

import smv_logo from '../../img/smv-logo-black.png';

import './Header.scss';

export default withRouter(class Header extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };

  }

  render() {
    return (
      <header>
        <Navbar color="light" light expand="md">
          <Container>
            <NavbarBrand href="/"><img src={smv_logo} alt="" />Strategic Motion</NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <UserContext.Consumer>
                  {({user, logout}) => {
                    return user ? this.authenticated(user, logout) : this.noAuth();
                  }}
                </UserContext.Consumer>
              </Nav>
            </Collapse>

          </Container>
        </Navbar>
      </header>
    )
  }

  renderMenuAction() {
    if ((/^\/[^/]*?\/?$/.exec(this.props.location.pathname)) !== null) {
      return ( <i className="fas fa-fw fa-bars toggleMenu"></i> );
    }
    return ( <i className="fas fa-fw fa-arrow-left toggleMenu" onClick={this.navigateBack.bind(this)}></i> );
  }

  closeMenu() {
    this.setState({isOpen: false});
  }

  openMenu() {
    this.setState({isOpen: true});
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  navigateBack(event) {
    event.stopPropagation();
    //let prefix = (this.props.history.location.pathname).endsWith('/') ? '' : '/';
    //console.log(`${this.props.history.location.pathname}${prefix}..`);
    this.props.history.replace(`..`);
  }

  authenticated(user, logout) {
    return (
      <UncontrolledDropdown>
        <DropdownToggle nav caret>
          <i aria-hidden="true" className="fas fa-user fa-fw"></i>{' '}{user.email}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>
            <Link to="/account"><i className="fas fa-user fa-fw"></i> My Profile</Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/settings"><i className="fas fa-cog fa-fw"></i> Settings</Link>
          </DropdownItem>
          <DropdownItem divider />

          <PermissionProvider roles={[Role.ADMIN]}>
            <DropdownItem>
              <Link to="/admin"><i className="fas fa-tools fa-fw"></i> Administration</Link>
            </DropdownItem>
            <DropdownItem divider />
          </PermissionProvider>


          <DropdownItem>
            <Link to="" onClick={logout}><i className="fas fa-sign-out-alt fa-fw"></i> Sign Out</Link>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }

  noAuth() {
    return (
      <NavItem>
        <NavLink href="/register">Sign Up</NavLink>
      </NavItem>
    )
  }

}
)