import axios from 'axios';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const API_BASE = isLocalhost ? 'http://localhost:8080' : 'https://api.strategicinteractionvideo.com';//;

/*axios.defaults.baseURL = API_BASE;*/
axios.defaults.withCredentials = true;
axios.defaults.credentials = 'same-origin';
axios.defaults.crossDomain = true;

export default class APIClient {

  static errorHandler(error) {
    console.log("APIClient Error", {...error});
    return Promise.reject(error);
  }

  static successHandler(response) {
    return response.data;
  }

  /****************
   * AUTH
   ****************/

  static register(email, password) {

  }

  static login(email, password, keepSignedIn = false) {
    return axios.post(`${API_BASE}/auth/login`, {
        email: email,
        password: password,
        keepSignedIn: keepSignedIn
      })
      .then(APIClient.successHandler)
      .catch(APIClient.errorHandler);
  }

  static logout() {
    return axios.post(`${API_BASE}/auth/logout`, {})
      .then(APIClient.successHandler)
      .catch(APIClient.errorHandler);
  }

  static getCurrentUser() {
    return axios.get(`${API_BASE}/auth/user`)
      .then(APIClient.successHandler)
      .catch(APIClient.errorHandler);
  }


  /****************
   * TRAININGS
   ****************/

  static getTrainings() {
    return axios.get(`${API_BASE}/trainings`)
      .then(APIClient.successHandler)
      .catch(APIClient.errorHandler);
  }

  static getTraining(trainingId) {
    return axios.get(`${API_BASE}/trainings/${trainingId}`)
      .then(APIClient.successHandler)
      .catch(APIClient.errorHandler);
  }

  static getVideos(trainingId) {
    return axios.get(`${API_BASE}/trainings/${trainingId}/videos`)
      .then(APIClient.successHandler)
      .catch(APIClient.errorHandler);
  }

  static getVideo(trainingId, videoId) {
    return axios.get(`${API_BASE}/trainings/${trainingId}/videos/${videoId}`)
      .then(APIClient.successHandler)
      .catch(APIClient.errorHandler);
  }

  static getVideoURL(trainingId, videoId) {
    return `${API_BASE}/trainings/${trainingId}/videos/${videoId}/media`
  }

  static getVideoFAQs(trainingId, videoId) {
    return axios.get(`${API_BASE}/trainings/${trainingId}/videos/${videoId}/faqs`)
      .then(APIClient.successHandler)
      .catch(APIClient.errorHandler);
  }

  static askQuestion(trainingId, videoId, question) {
    return axios.post(`${API_BASE}/trainings/${trainingId}/videos/${videoId}/ask`, {
        question: question
      })
      .then(APIClient.successHandler)
      .catch(APIClient.errorHandler);
  }

}