import React, { Component } from 'react';
import {Route, Switch} from 'react-router-dom';


import {AuthenticationProvider} from '../../contexts/AuthenticationProvider';

import TrainingList from './TrainingList';
import Training from '../learner/training/Training';
import Error404 from '../common/Error404';

export default class Home extends Component {
  render() {
    return (
      <AuthenticationProvider>
        <Switch>
          <Route path={`${this.props.match.url}trainings/:trainingId`} component={Training} />
          <Route exact path={`${this.props.match.url}`} component={TrainingList} />
          <Route component={Error404} />
        </Switch>
      </AuthenticationProvider>
    )
  }
}
