import React, { Component} from 'react';
//import { Link } from 'react-router-dom';
import { Container, Row, Col, Input, ListGroup} from 'reactstrap';


//import brickseparator from '../img/brickseparator.png';

export default class Account extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col md="2">
          </Col>
          <Col md="8" className="trainingListContainer">
            <h2><i className="fa fa-fw fa-user"></i> My Profile</h2>

            <Input ng-model="filterQuery" placeholder="Filter" type="text" onChange={(e) => {this.setState({filter: e.target.value})}} />
            <br/>
            <ListGroup className=" trainings">
            </ListGroup>
          </Col>
        </Row>
      </Container>
    )
  }
}
