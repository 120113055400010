
import React, { Component } from 'react';
import { ListGroup, Button, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Container, Input, Row, Col} from 'reactstrap';

import Menu from './Menu';
import Loading from '../common/Loading';

import APIClient from '../../APIClient';
import ArrayUtil from '../../Utilities/ArrayUtil';
import IDB from '../../Utilities/IDB';

import './TrainingList.scss';

export default class TrainingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortCriteria: [
        {
          field: "title",
          descending: true
        }
      ],
      filter: ""
    };
  }

  componentDidMount() {
    IDB.getTrainings().then(trainings => {
      this.setState({trainings: trainings});
      return APIClient.getTrainings();
    }).catch(error => {
      return APIClient.getTrainings();
    }).then(response => {
      this.setState({trainings: response.trainings});
      return response.trainings;
    }).then(trainings => {
      trainings.map(IDB.saveTraining);
    });
  }

  componentWillUpdate(nextProps, nextState) {
    //let filterCriteria = nextState.filters;
    let sortCriteria = nextState.sortCriteria;
    nextState.filteredTrainings = ArrayUtil.filter(nextState.trainings, [
      {
        field: "title",
        type: "contains",
        value: nextState.filter
      }
    ]);
    nextState.filteredTrainings = ArrayUtil.sort(nextState.filteredTrainings, sortCriteria);
  }

  render() {
    return (
      <Container>
        <Row>
          <Col md="3">
            <Menu />
          </Col>
          <Col md="9" className="trainingListContainer">
            <h2><i className="fa fa-fw fa-lg fa-graduation-cap"></i> My Trainings</h2>

            <Input ng-model="filterQuery" placeholder="Filter" type="text" onChange={(e) => {this.setState({filter: e.target.value})}} />
            <br/>
            <ListGroup className=" trainings">
              { this.renderTrainings() }
            </ListGroup>
          </Col>
        </Row>
      </Container>
    )
  }

 renderTrainings() {
    if(!this.state.filteredTrainings) {
      return ( <Loading className="listItem" /> )
    }
    else if(this.state.filteredTrainings.length === 0) {
      return (<strong>No matching results found...</strong>);
    }
    return this.state.filteredTrainings.map(training => {
      return (
        <ListGroupItem key={training.id} href="" className="{active: training.id === currentTraining.id}" ng-click="showTrainingDetails(training);">
          <div className="float-right"><Button href={`/trainings/${training.id}`}><i className="fa fa-play fa-lg fa-fw"></i> Start Training</Button></div>
          <ListGroupItemHeading>{training.title}</ListGroupItemHeading>
          <ListGroupItemText>{training.description}</ListGroupItemText>
        </ListGroupItem>
        );
      //return ();
        //<TrainingListItem training={training} key={training.number} match={this.props.match} />

    })
  }

  renderSortList() {
    return (
      <div className="popupDialog">
        Sort By
        <div className="">Date Traininged</div>
        <div className="">Training Number</div>
        <div className="">Grand total</div>
        <div className="">Buyer Name</div>
        <div className="">Item Count</div>
        <div className="">Lot Count</div>
        <div className="">Shipping Method</div>
        <div className="">Insurance</div>
        <div className="">Add. Chrg.</div>
        <div className="">Status</div>
        <div className="">Date Changed</div>
        <div className="">Country</div>
        <div className="">Note</div>
      </div>
    )
  }
}
