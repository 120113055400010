import React, { Component, Fragment } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import Loading from '../../common/Loading';

import APIClient from '../../../APIClient';
import Video from './Video';

//import IDB from '../../Utilities/IDB';

import './LearnerQuestion.scss';

export default class LearnerQuestionAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerVideo: null,
      question: null
    };
    this.bindEvents();
  }

  bindEvents() {
    this.onVideoEnded = this.onVideoEnded.bind(this);
  }

  componentDidMount() {
    console.log(this.props.question);
    if(!this.props.question.faq) {
      APIClient.askQuestion(this.props.training.id, this.props.video.id, this.props.question.question)
        .then(response => {
          this.setState({
            answerVideo: response.video
          })
        });
    }
    else {
      APIClient.getVideo(this.props.training.id, this.props.question.faq.answerVideoId)
        .then(response => {
          this.setState({
            answerVideo: response.video
          })
        });
    }
  }

  render() {
    return (
      <Modal isOpen={true}>
        <ModalHeader>{this.props.question.question}</ModalHeader>
        <ModalBody>
          {this.renderAnswer()}
        </ModalBody>
        <ModalFooter className="text-center">
          <Button color="primary" onClick={this.props.onClose}>Resume Training</Button>
        </ModalFooter>
      </Modal>
    )
  }


  renderDebug() {
    if(!this.props.training.debugMode)
      return null;
    return (
      <div className="training-debug">
        <small>
          <Row>
            <Col md="4">Video ID:</Col>
            <Col md="8">{this.state.answerVideo.id}</Col>
          </Row>
          <Row>
            <Col md="4">Video Title:</Col>
            <Col md="8">{this.state.answerVideo.title}</Col>
          </Row>
        </small>
      </div>
    );
  }

  renderAnswer() {
    if(!this.state.answerVideo) {
      return ( <Loading /> );
    }
    return (
      <Fragment>
        <div className="videoArea">
          {this.renderDebug()}
          <Video video={this.state.answerVideo} isPaused={false} onEnded={this.onVideoEnded} />
        </div>
      </Fragment>
    );

  }

  onVideoEnded() {
    /*if(this.props.onClose) {
      this.props.onClose();
    }*/
  }




}
