import React, { Component } from 'react';
import { Row, Col, Container} from 'reactstrap';


import './Footer.scss';

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <Container>
          <Row>
            <Col md="5" sm="6" id="footer-logo">
              <h2>Strategic Motion</h2>
              <small style={{display: "block"}}>&copy; {new Date().getFullYear()} Strategic Motion, Inc. <br />
                All Rights Reserved.</small>
            </Col>
            <Col md="1" sm="1" >
            </Col>
            <Col md="5" sm="5" >
              <Row>
                <Col md="4">
                  <h3>Site</h3>
                  <ul className="list-unstyled">
                    <li><a href="http://www.strategicmotion.co/#idea-anchor">The Idea</a></li>
                    <li><a href="http://www.strategicmotion.co/#research-anchor">The Research</a></li>
                    <li><a href="http://www.strategicmotion.co/#people-anchor">The People</a></li>
                    <li><a href="http://www.strategicmotion.co/#contact-anchor">Contact Us</a></li>
                  </ul>
                </Col>
                <Col md="4">
                  <h3>About</h3>
                  <ul className="list-unstyled">
                    <li><a href="http://www.strategicmotion.co/#">Strategic Motion</a></li>
                    <li><a href="http://www.strategicmotion.co/#">Careers</a></li>
                    <li><a href="http://www.strategicmotion.co/#">Investor Relations</a></li>
                  </ul>
                </Col>
                <Col md="4">
                  <h3>Contribute</h3>
                  <ul className="list-unstyled">
                    <li><a href="http://www.strategicmotion.co/#">Send Feedback</a></li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>
    )
  }
}
