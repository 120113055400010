import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';

import TrainingGraph, {VideoType} from '../../../Utilities/TrainingGraph';
import IDB from '../../../Utilities/IDB';
import Video from './Video';
import APIClient from '../../../APIClient';

import './TrainingContent.scss';

import LearnerQuestion from './LearnerQuestion';
import LearnerQuestionAnswer from './LearnerQuestionAnswer';
import LearnerAssessment from './LearnerAssessment';
import TrainingNavigation from './TrainingNavigation';


export default class TrainingContent extends Component {
  constructor(props) {
    super(props);
    const graph = new TrainingGraph(props.training.graph, props.surveyResponses);
    this.state = {
      training: props.training,
      surveyResponses: props.surveyResponses,
      videos: props.videos,
      graph: graph,
      currentVideo: null,
      isPaused: false,
      askingQuestion: false,
      assessingLearner: false,
      trainingEnded: false,
      videoHistory: [graph.getCurrentNode()]
    };
    this.bindEvents();
  }

  bindEvents() {
    this.onVideoEnded = this.onVideoEnded.bind(this);
    this.showAskQuestionDialog = this.showAskQuestionDialog.bind(this);
    this.onAssessmentResponse = this.onAssessmentResponse.bind(this);
    this.askQuestion = this.askQuestion.bind(this);
    this.handleKeypress = this.handleKeypress.bind(this);
    this.onNavigate = this.onNavigate.bind(this);
    this.onVideoSkipped = this.onVideoSkipped.bind(this);
  }

  handleKeypress(event) {
    if(event.keyCode === 81) {
      this.showAskQuestionDialog();
    }
  }

  componentWillMount() {
    document.addEventListener("keydown", this.handleKeypress, false);
  }


  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeypress, false);
  }

  componentDidMount() {
    this.setVideo(this.state.graph.getCurrentNode().resourceId);
  }


  render() {
    if(this.state.trainingEnded)
      return null;
    return (
      <Row>
        <TrainingNavigation graph={this.state.graph} videos={this.state.videos} onNavigate={this.onNavigate} onVideoSkipped={this.onVideoSkipped} />
        <Col md="12" className="training">
          <div className="videoArea">
            {this.renderDebug()}
            <div className="training-qa" onClick={this.showAskQuestionDialog}><i className="fa fa-question fa-fw" aria-hidden="true"></i></div>
            <Video video={this.state.currentVideo} isPaused={this.state.isPaused} onEnded={this.onVideoEnded} />
          </div>
        </Col>
        {this.renderModal()}
      </Row>
    )
  }

  renderDebug() {
    if(!this.state.training || !this.state.training.debugMode || !this.state.currentVideo)
      return null;
    return (
      <div className="training-debug">
        <small>
          <Row>
            <Col md="4">Video ID:</Col>
            <Col md="8">{this.state.currentVideo.id}</Col>
          </Row>
          <Row>
            <Col md="4">Video Title:</Col>
            <Col md="8">{this.state.currentVideo.title}</Col>
          </Row>
        </small>
      </div>
    );
  }

  renderModal() {
    if(this.state.askingQuestion) {
      return (
        <LearnerQuestion training={this.state.training} video={this.state.currentVideo} onAsk={this.askQuestion} onCancel={() => {this.setState({askingQuestion: false, isPaused: false})} }  />
      );
    }
    else if(this.state.answeringQuestion) {
      return (
        <LearnerQuestionAnswer training={this.state.training} video={this.state.currentVideo} question={this.state.question} onClose={() => {this.setState({answeringQuestion: false, question: null, isPaused: false})} }  />
      );
    }
    else if(this.state.assessingLearner) {
      return (
        <LearnerAssessment training={this.state.training} video={this.state.currentVideo} onAnswer={this.onAssessmentResponse}  />
      );
    }
    return null;
  }

  showAskQuestionDialog() {
    this.setState({
      askingQuestion: true,
      isPaused: true
    });
  }

  askQuestion(question, faq) {
    console.log("Asking", question);
    this.setState({
      askingQuestion: false,
      answeringQuestion: true,
      question: {
        question: question,
        faq: faq
      },
      isPaused: true
    });
  }

  showQuestionAnswerDialog(question, answerVideo) {
    this.setState({
      answeringQuestion: true,
      isPaused: true
    });
  }

  onAssessmentResponse(response) {
    this.nextNode(response.id);
    this.setState({assessingLearner: false, isPaused: false});
  }

  onVideoEnded() {
    if(this.state.graph.getCurrentNode().type === VideoType.question.type) {

      this.setState({assessingLearner: true, isPaused: true});
    }
    else {
      this.nextNode()
    }

  }

  nextNode(response) {
    const node = this.state.graph.nextNode(response);
    if(node) {
      this.setVideo(node.resourceId);
      this.setState((state, props) => ({
        videoHistory: [...state.videoHistory, node]
      }));
    }
    else if(this.props.onTrainingCompleted) {
      this.setState({trainingEnded: true});
      this.props.onTrainingCompleted();
    }
  }

  setVideo(videoId) {
    IDB.getVideo(videoId).then(video => {
      this.setState({currentVideo: video});
      return APIClient.getVideo(this.state.training.id, videoId);
    }).catch(error => {
      return APIClient.getVideo(this.state.training.id, videoId);
    }).then(response => {
      this.setState({currentVideo: response.video});
      return response.videos;
    }).then(video => IDB.saveVideo);

    //TODO: validate that this is a valid possible action to avoid skipping ahead


    this.currentVideoFAQs = null;
    //Get FAQs
    //console.log("Getting FAQs", this.currentVideo);
    /*APIClient.loadVideoFAQs(this.currentTraining.id, this.currentVideo.id).then(function(faqs) {
        this.currentVideoFAQs = faqs;
        //this.currentTraining.populateFAQs(this.faqList);
        return this.currentVideoFAQs;
    }).catch(function(reason) {
      //console.log("Error:", reason);
    });*/
  }

  onNavigate(history, historyIndex) {
    console.log("Shoud go back and play" , historyIndex);

    this.state.graph.goToHistory(historyIndex);
    this.setVideo(this.state.graph.getCurrentNode().resourceId);
  }


  onVideoSkipped() {
    this.setState({
      currentVideo: null
    });
    this.onVideoEnded();
  }

}
