export default class ArrayUtil {

  static filter(array, criteria) {
    return array.filter(item => {
      for(const criterion of criteria) {
        const property = objResolve(item, criterion.field);
        try {
          switch(criterion.type) {
            case "between":
              if(!(criterion.value1 <= property && property <= criterion.value2 )) {
                return false;
              }
            break;
            case "greater":
              if(property < criterion.value) {
                return false;
              }
            break;
            case "less":
              if(property > criterion.value) {
                return false;
              }
            break;
            case "equal":
              if(property !== criterion.value) {
                return false;
              }
            break;
            case "different":
              if(property === criterion.value) {
                return false;
              }
            break;
            case "contains":
              if(!property.toString().toLowerCase().includes(criterion.value.toLowerCase())) {
                return false;
              }
            break;
            default:
          }
        }
        catch(e) {
          
        }
      }
      return true;
    });
  }



  static sort(array, criteria) {
    return ([].concat(array)).sort((a, b) => {
      for(const criterion of criteria) {
        const propertyA = objResolve(a, criterion.field);
        const propertyB = objResolve(b, criterion.field);
        if(propertyA > propertyB) {
          return criterion.descending ? -1 : 1;
        }
        else if(propertyA < propertyB) {
          return criterion.descending ? 1 : -1;
        }
      }
      return 0;
    });
  }
}

const objResolve = function(rootObj, string) {
  let value = rootObj;
  let parts = string.split('.');
  while(parts.length !== 0) {
    value = value[parts.shift()];
  }
  return value;
}