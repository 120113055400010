import React, { Component} from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col} from 'reactstrap';


//import brickseparator from '../img/brickseparator.png';

export default class Error404 extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col md={{offset: 2, size: 8}} sm="12">
            <h1>Well, this is awkward...</h1>
            
          
            <h2>Error (404). Page not found.</h2>
            <h4>Clearly something went wrong along the way. We can't find the page you're looking for. You may want to head back to the <Link to="/">Home Page</Link>.</h4>
          </Col>
        </Row>
      </Container>
    )
  }
}
