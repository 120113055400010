import idb from 'idb';

export default class IDB {
  static get DB_VERSION() {
    return 1;
  }

  static get DB_NAME() {
    return 'smv';
  }

  static get TRAINING_STORE() {
    return 'trainings';
  }

  static get TRAINING_VIDEO_STORE() {
    return 'training_videos';
  }

  static get TEMP_TRAINING_VIDEO_STORE() {
    return 'temp_reviews';
  }

  static get TRAINING_INDEX() {
    return 'id';
  }

  static getDB(dbName = IDB.DB_NAME, version = IDB.DB_VERSION) {
    if(!('indexedDB' in window)) {
      return Promise.reject(new Error("IndexedDB is not supported"));
    }
    else if(!IDB.dbPromise) {
      IDB.dbPromise = idb.open(dbName, version, IDB.onUpgradeNeeded);
    }
    return IDB.dbPromise;
  }

  static onUpgradeNeeded(upgradeDB) {
    switch(upgradeDB.oldVersion) {
      case 0:
        upgradeDB.createObjectStore(IDB.TRAINING_STORE);
        // falls through
      case 1:
        let reviews = upgradeDB.createObjectStore(IDB.TRAINING_VIDEO_STORE);
        reviews.createIndex(IDB.TRAINING_INDEX, IDB.TRAINING_INDEX, {unique: false});

        let tempReviews = upgradeDB.createObjectStore(IDB.TEMP_TRAINING_VIDEO_STORE, {keyPath: "id", autoIncrement: false});
        tempReviews.createIndex(IDB.TRAINING_INDEX, IDB.TRAINING_INDEX, {unique: false});
        // falls through
      default:
    }
  }

  /****** TRAININGS ******/
  
  static getTraining(videoId) {
    return IDB.getDB().then(db => {
      return db.transaction(IDB.TRAINING_STORE)
        .objectStore(IDB.TRAINING_STORE)
        .get(parseInt(videoId))
        .then(value => {
          return value ? value : Promise.reject('Object not found in database');
        });
    });
  }

  static getTrainings() {
    return IDB.getDB().then(db => {
      return db.transaction(IDB.TRAINING_STORE)
        .objectStore(IDB.TRAINING_STORE)
        .getAll()
        .then(trainings => {
          return trainings.length === 0 ? Promise.reject('Object not found in database') : trainings;
        });
    });
  }

  static saveTraining(training) {
    return IDB.getDB().then(db => {
      const tx = db.transaction(IDB.TRAINING_STORE, 'readwrite');
      tx.objectStore(IDB.TRAINING_STORE).put(training, parseInt(training.id));
      return tx.complete;
    });
  }

  /****** ORDER ITEMS ******/

  static getVideo(videoId) {
    return IDB.getDB().then(db => {
      return db.transaction(IDB.TRAINING_VIDEO_STORE)
        .objectStore(IDB.TRAINING_VIDEO_STORE)
        .get(parseInt(videoId))
        .then(value => {
          return value ? value : Promise.reject('Object not found in database');
        });
    });
  }

  static getVideos(videoId) {
    return IDB.getDB().then(db => {
      return db.transaction(IDB.TRAINING_VIDEO_STORE)
        .objectStore(IDB.TRAINING_VIDEO_STORE)
        .index(IDB.TRAINING_INDEX)
        .getAll(parseInt(videoId))
        .then(videos => {
          return videos.length === 0 ? Promise.reject('Object not found in database') : videos;
        });
    });
  }

  static saveVideo(video) {
    return IDB.getDB().then(db => {
      const tx = db.transaction(IDB.TRAINING_VIDEO_STORE, 'readwrite');
      tx.objectStore(IDB.TRAINING_VIDEO_STORE).put(video, parseInt(video.id));
      return tx.complete;
    });
  }

  getTempReviewsByTraining(videoId) {
    return IDB.getDB().then(db => {
      return db.transaction(IDB.TEMP_TRAINING_VIDEO_STORE)
        .objectStore(IDB.TEMP_TRAINING_VIDEO_STORE)
        .index(IDB.TRAINING_INDEX)
        .getAll(parseInt(videoId));
    });
  }

  saveTempReview(review) {
    return IDB.getDB().then(db => {
      const tx = db.transaction(IDB.TEMP_TRAINING_VIDEO_STORE, 'readwrite');
      tx.objectStore(IDB.TEMP_TRAINING_VIDEO_STORE).put(review,);
      return tx.complete;
    });
  }


  clearTempReviewByTraining(videoId) {
    return IDB.getDB().then(db => {
      return db.transaction(IDB.TEMP_TRAINING_VIDEO_STORE, 'readwrite')
        .objectStore(IDB.TEMP_TRAINING_VIDEO_STORE)
        .index(IDB.TRAINING_INDEX)
        .clear();
    });
  }

  deleteTempReview(reviewId) {
    return IDB.getDB().then(db => {
      return db.transaction(IDB.TEMP_TRAINING_VIDEO_STORE, 'readwrite')
        .objectStore(IDB.TEMP_TRAINING_VIDEO_STORE)
        .delete(parseInt(reviewId));
    });
  }
}