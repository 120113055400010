import React, { Component } from 'react';
import {Redirect} from "react-router-dom";

//const SomeComponent = withRouter(props => <AuthenticationProvider {...props}/>);

import {UserContext} from './UserProvider';

export class PermissionProvider extends Component {
  render() {
    return (
      <UserContext.Consumer>
        {({user}) => {
          let valid = false;
          if(this.props.roles) {
            for(let i = 0; i < user.privileges.length; i++) {
              if(this.props.roles.includes(user.privileges[i].id)) {
                valid = true;
                break;
              }
            }
          }

          return valid ? this.props.children : this.forbidden(this.props.redirect);
        }}
      </UserContext.Consumer>

    );
  }

  forbidden(redirect) {
    if(redirect) {
      return <Redirect to={{
        pathname: "/forbidden",
        state: { from: document.location.pathname }
        }}

      />
    }
    //If redirect was not requested, just don't render anything
    return null;
  }
}