import React, { Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Alert, Button, Container, Form, FormGroup, Label, Input, Row, Col} from 'reactstrap';
import './Login.scss';

import {UserContext} from '../../contexts/UserProvider';

import APIClient from '../../APIClient';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      keepSignedIn: false,
      processing: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  render() {
    return (
      <div className="back login">
        <UserContext.Consumer>
          {({user, setUser}) => {
            if(!this.state.setUser) {
              this.setState({ setUser: setUser });
            }
            if(user) {
              return <Redirect to={this.props.location && this.props.location.state && this.props.location.state.from ? this.props.location.state.from : '/'} />
            }
          }}
        </UserContext.Consumer>
        <Container>
          <Row>
            <Col md="7" sm="12" className="splash">
              <h1>When Video Talks Back</h1>
              <h2><strong>redefining</strong> Corporate Training</h2>
            </Col>

            <Col md="5" sm="12">
              <Form className="form-signin" onSubmit={this.submit}>
                <h2 className="form-signin-heading">Member Access</h2>
                { this.state.error ? (<Alert color="danger" className="userError">Invalid email or password</Alert>) : <br /> }

                <Label className="sr-only" for="InputEmail">
                  Email address
                </Label>
                <Input autoFocus className="form-control" id="email" name="email" value={this.state.email} placeholder="Email address" required type="email" onChange={this.handleChange} disabled={this.state.processing} />
                <Label className="sr-only" for="InputPassword">
                  Password
                </Label>
                <Input className="form-control" id="password" name="password" value={this.state.password} placeholder="Password" required type="password" onChange={this.handleChange} disabled={this.state.processing}/>
                <FormGroup check>
                  <Label>
                    <Input name="remember" type="checkbox" value="remember" ng-model="remember" />
                    Remember me
                  </Label>
                </FormGroup>
                <Button color="primary" block disabled={this.state.processing || !this.state.email || !this.state.password} type="submit">
                  Sign in
                </Button>
                <br/>
                <p className="text-right">
                  <Link to="/forgot">Forgot your password?</Link>
                </p>
                <p className="text-right">
                  <Link to="/register">Sign up for a new account</Link>
                </p>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  submit(event) {
    this.setState({
      error: null,
      processing: true
    });
    event.preventDefault();

    APIClient.login(this.state.email, this.state.password, this.state.keepSignedIn).then(result => {
      this.state.setUser(result.user);
    })
    .catch(error => {
      this.setState({
        error: 'Invalid email or password',
        processing: false
      });
    });
  }
}
