import React, { Component } from 'react';
import { DropdownMenu, DropdownItem, UncontrolledButtonDropdown, DropdownToggle} from 'reactstrap';

import {VideoType} from '../../../Utilities/TrainingGraph';
import './TrainingNavigation.scss';

export default class TrainingNavigation extends Component {
  constructor(props) {
    super(props);

    let videos = {};
    for(let video of props.videos) {
      videos[video.id] = video;
    }
    this.state = {
      videos: videos
    }
    this.bindEvents();
  }

  bindEvents() {
    this.onNavigate = this.onNavigate.bind(this);
    this.onVideoSkipped = this.onVideoSkipped.bind(this);
  }

  render() {
    return (
      <UncontrolledButtonDropdown className="trainingNavigation">
        <DropdownToggle caret>
          Training Navigation
        </DropdownToggle>
        <DropdownMenu right={false}>
          {this.renderHistory()}
          <DropdownItem divider />

          <DropdownItem onClick={this.onVideoSkipped} disabled={!this.isVideoSkippable()}>
            <i className="fas fa-step-forward fa-fw"></i> Skip current video
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    )
  }

  renderHistory() {
    return this.props.graph.nodeHistory.map((node, index) => (
      <DropdownItem key={node.id} className="video" onClick={() => this.onNavigate(index)}>
        <i className="fas fa-play fa-fw"></i>
        <i className="fas fa-film fa-fw"></i>
        {this.state.videos[node.resourceId].title}
      </DropdownItem>
    ));
  }

  isVideoSkippable() {
    return this.props.graph.getCurrentNode().type !== VideoType.question.type;
  }

  onNavigate(historyIndex) {
    if(this.props.onNavigate) {
      this.props.onNavigate(this, historyIndex);
    }
  }

  onVideoSkipped() {
    if(this.props.onVideoSkipped) {
      this.props.onVideoSkipped();
    }
  }
}