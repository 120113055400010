import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import {UserProvider} from './contexts/UserProvider';

import './App.css';


import Header from './components/common/Header';
import Home from './components/learner/Home';
import Login from './components/common/Login';
import Account from './components/common/Account';
import Fulfillment from './components/common/Error404';
import Analytics from './components/common/Error404';
import Footer from './components/common/Footer';
import Error404 from './components/common/Error404';

class App extends Component {
  render() {
    return (
      <UserProvider>
        <Router>
          <Fragment>
            <Header />
            <div className="App">

              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/account" component={Account} />
                <Route path="/fulfillment" component={Fulfillment} />
                <Route path="/analytics" component={Analytics} />
                <Route path="/" component={Home} />
                <Route component={Error404} />
              </Switch>
            </div>

            <Footer />
          </Fragment>
        </Router>
      </UserProvider>
    );
  }
}

export default App;
