import React, { Component } from 'react';

import APIClient from '../APIClient';
//import PWA from '../PWA';


export const UserContext = React.createContext();

export class UserProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: JSON.parse(localStorage.getItem('User')),
      setUser: this.setUser.bind(this),
      logout: this.logout.bind(this)
    };
  }

  componentDidMount() {
    APIClient.getCurrentUser().then(response => {
      this.setUser(response.user);
    }).catch(() => {
      this.setUser(null);
    });
  }

  render() {
    return (
      <UserContext.Provider value={{...this.state}}>
        {this.props.children}
      </UserContext.Provider>
    );
  }

  setUser(user) {
    this.setState({ user: user });
    localStorage.setItem('User', JSON.stringify(user));
    /*if(user) {
      PWA.initFirebase();
    }*/
  }

  logout() {
    APIClient.logout().then(()=> {
      this.setUser(null);
    });
  }
}