import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import { DropdownMenu, DropdownItem, Nav, UncontrolledDropdown, DropdownToggle, NavItem, NavLink} from 'reactstrap';


//import {UserContext} from '../../Contexts/UserProvider';

import './Menu.scss';

export default withRouter(class Header extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };

  }

  render() {
    return (
      <Nav vertical className="menu">
        <NavItem>
          <NavLink href="/"><i aria-hidden="true" className="fa fa-lg fa-graduation-cap fa-fw"></i>My Trainings</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/stats"><i aria-hidden="true" className="fa fa-line-chart fa-lg fa-fw"></i>Report Card</NavLink>
        </NavItem>
      </Nav>
    )
  }

  renderMenuAction() {
    if ((/^\/[^/]*?\/?$/.exec(this.props.location.pathname)) !== null) {
      return ( <i className="fas fa-fw fa-bars toggleMenu"></i> );
    }
    return ( <i className="fas fa-fw fa-arrow-left toggleMenu" onClick={this.navigateBack.bind(this)}></i> );
  }

  closeMenu() {
    this.setState({isOpen: false});
  }

  openMenu() {
    this.setState({isOpen: true});
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  navigateBack(event) {
    event.stopPropagation();
    //let prefix = (this.props.history.location.pathname).endsWith('/') ? '' : '/';
    //console.log(`${this.props.history.location.pathname}${prefix}..`);
    this.props.history.replace(`..`);
  }

  authenticated(user, logout) {
    return (
      <UncontrolledDropdown>
        <DropdownToggle nav caret>
          <i aria-hidden="true" className="fa fa-user fa-lg"></i> {user.email}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>
            <Link to="/account"><i className="fa fa-user fa-lg fa-fw"></i> My Profile</Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/settings"><i className="fa fa-cog fa-lg fa-fw"></i> Settings</Link>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            <Link to="/logout"><i className="fa fa-sign-out fa-lg fa-fw"></i> Log Out</Link>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }

  noAuth() {
    return (
      <NavItem>
        <NavLink href="/register">Sign Up</NavLink>
      </NavItem>
    )
  }
}
)