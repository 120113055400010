
import React, {Component} from 'react';

export default class Loading extends Component {
  render() {
    return (
      <div className={`loading ${this.props.className}`}>
        <i className="fas fa-spinner fa-pulse fa-2x fa-fw"></i>
        Loading...
      </div>
    )
  }
}