import React, { Component } from 'react';
import { Input, FormGroup, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import APIClient from '../../APIClient';

//import IDB from '../../Utilities/IDB';

import './LearnerAssessment.scss';

export default class LearnerAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAnswer: null
    };
    this.bindEvents();
  }

  bindEvents() {
    this.onAnswer = this.onAnswer.bind(this);
  }

  render() {
    return (
      <Modal isOpen={true}>
        <ModalHeader>Learning Assessment</ModalHeader>
        <ModalBody>
          <h4>{this.props.video.options.question}</h4>
          <FormGroup tag="fieldset">
            {this.renderAnswers()}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onAnswer} disabled={!this.state.selectedAnswer}>Submit Answer</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderAnswers() {
    return Object.keys(this.props.video.options.answers).map(key => {
      const answer = this.props.video.options.answers[key];
      return (
        <FormGroup check key={answer.id}>
          <Label check>
            <Input type="radio" name="answer" value={answer.id} onChange={(e) => { this.onAnswerChange(e, answer) } } />
            {answer.text}
          </Label>
        </FormGroup>
      );
    });
    
  }

  onAnswerChange(e, answer) {
    this.setState({
      selectedAnswer: answer
    });
  }

  onAnswer() {
    if(this.props.onAnswer) {
      this.props.onAnswer(this.state.selectedAnswer);
    }
  }




}
