import React, { Component } from 'react';
import {Redirect} from "react-router-dom";

//const SomeComponent = withRouter(props => <AuthenticationProvider {...props}/>);

import {UserContext} from './UserProvider';

export class AuthenticationProvider extends Component {
  render() {
    return (
      <UserContext.Consumer>
        {({user}) => {
          if(!user) {
            return <Redirect to={{
              pathname: "/login",
              state: { from: document.location.pathname }
              }}
            />
          }
          return this.props.children;
        }}
      </UserContext.Consumer>

    );
  }
}